import { useEffect, useState, Suspense, lazy, useRef } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import io from "socket.io-client";
import socket from "./socket/socket";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import {
  selectBets,
  selectUpdateBet,
  selectSportsEvents,
  selectTheme,
  selectRmgt,
  selectTriggerBetslip,
} from "./redux/_front/globalSelectors";
import { clearBets } from "./redux/_front/betslipSlice";

import { updateBetOdds } from "./redux/_front/betslipSlice";
import { handleUpdateSlider } from "./redux/_front/sliderSlice";
import { updateUserDetails } from "./redux/_front/userSlice";
import { setRmgt } from "./redux/_common/rmgtSlice";
import { fetchRmgt } from "./api/_common/rmgtApi";
//for loader
import Loader from "./components/loader/Loader";

//import { useSocket, isSocketConnected, socket } from './socket/socket';

//for admins
const PartnerRegister = lazy(() =>
  import("./pages/admin/register/Register.page")
);
const PartnerLogin = lazy(() => import("./pages/admin/login/Login.page"));

const AdminDashboard = lazy(() => import("./pages/admin/Dashboard.page"));
const AdminUsers = lazy(() => import("./pages/admin/Users.page"));
const AdminEvents = lazy(() => import("./pages/admin/Events.page"));
const AdminExchangeEvents = lazy(() =>
  import("./pages/admin/ExchangeEvents.page")
);
const AdminEventDetails = lazy(() => import("./pages/admin/EventDetails.page"));
const AdminExchangeEventDetails = lazy(() =>
  import("./pages/admin/ExchangeEventDetails.page")
);
const AdminTickets = lazy(() => import("./pages/admin/Tickets.page"));
const AdminExchangeTickets = lazy(() =>
  import("./pages/admin/ExchangeTickets.page")
);
const AdminCasinoSlot = lazy(() => import("./pages/admin/CasinoSlot.page"));
const AdminRiskMgt = lazy(() => import("./pages/admin/RiskMgt.page"));
const AdminDeposits = lazy(() => import("./pages/admin/Deposits.page"));
const AdminWithdrawals = lazy(() => import("./pages/admin/Withdrawals.page"));
const AdminPaymentMethods = lazy(() =>
  import("./pages/admin/PaymentMethods.page")
);
const AdminEarnings = lazy(() => import("./pages/admin/Earnings.page"));
const AdminEditUser = lazy(() => import("./pages/admin/EditUser.page"));
const AdminPages = lazy(() => import("./pages/admin/Pages.page"));
const EditPage = lazy(() => import("./pages/admin/EditPage.page"));
const AdminSupportChat = lazy(() => import("./pages/admin/SupportChat.page"));
const AdminBannersGallery = lazy(() =>
  import("./pages/admin/BannersGallery.page")
);
const AdminSettings = lazy(() => import("./pages/admin/Settings.page"));
const EmailTemplates = lazy(() => import("./pages/admin/EmailTemplates.page"));
const ViewScore = lazy(() => import("./pages/admin/ViewScore.page"));
//admin agents
const AdminPartnerHistory = lazy(() =>
  import("./pages/admin/AdminPartnerHistory.page")
);
const AdminPartnerPayments = lazy(() =>
  import("./pages/admin/AdminPartnerPayments.page")
);
const AdminUserCreditHistory = lazy(() =>
  import("./pages/admin/AdminUserCreditHistory.page")
);

const ExResults = lazy(() => import("./pages/admin/ExResults.page"));

//////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////

//for users
const Register = lazy(() => import("./pages/register/Register.page"));
const Login = lazy(() => import("./pages/login/Login.page"));
const DenyAccess = lazy(() => import("./pages/login/DenyAccess.page"));

const ResetPassword = lazy(() => import("./pages/login/ResetPassword.page"));
const HomePage = lazy(() => import("./pages/home/Home.page"));
const TopContentMenu = lazy(() =>
  import("./pages/topContentNav/TopContentNav.page")
);
const TopContentNavFilterPage = lazy(() =>
  import("./pages/topContentNav/TopContentNavFilter.page")
);
const PreSportsPage = lazy(() => import("./pages/presports/Presports.page"));
const InplaySportsPage = lazy(() => import("./pages/inplay/Inplay.page"));
const FullGamePage = lazy(() => import("./pages/game/FullGame.page"));
const ExchangeSportsPage = lazy(() => import("./pages/exchange/Exchange.page"));
const ExchangeEventView = lazy(() =>
  import("./pages/exchange/ExchangeEventView.page")
);
const ExchangeMyBets = lazy(() =>
  import("./pages/exchange/ExchangeMyBets.page")
);
const CasinoPage = lazy(() => import("./pages/casino/Casino.page"));
const SlotPage = lazy(() => import("./pages/slot/Slot.page"));
const MyBet = lazy(() => import("./pages/mybet/MyBet.page"));
const SearchPage = lazy(() => import("./pages/search/Search.page"));
const NotificationsPage = lazy(() =>
  import("./pages/notification/Notifications.page")
);
const MessagesChat = lazy(() => import("./pages/messages/Messages.page"));
const ProfilePage = lazy(() => import("./pages/profile/Profile.page"));
const BankPage = lazy(() => import("./pages/bank/Bank.page.jsx"));
const PartnerPage = lazy(() => import("./pages/partner/Partner.page"));
const ViewPage = lazy(() => import("./pages/page/ViewPage.page.jsx"));
// const MyAdmin = lazy(() => import("./pages/myAdmin/MyAdmin"));
// const SettleQuizPage = lazy(() => import("./pages/SettleQuizPage/SettleQuizPage"));

//function
function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user); // Use the selectUser selector
  const token = useSelector((state) => state.user.token); // Use the selectToken selector
  const stateSportsEvents = useSelector(selectSportsEvents);
  const socketRef = useRef(socket);

  const bets = useSelector(selectBets); // Use the selectBets selector
  const updateBet = useSelector(selectUpdateBet); // Use the selectUpdateBet selector
  const theme = useSelector(selectTheme);
  const rmgt = useSelector(selectRmgt);
  const triggerBetslip = useSelector(selectTriggerBetslip);

  useEffect(() => {
    dispatch(handleUpdateSlider(token));
    //update user details
    if (token) {
      dispatch(updateUserDetails({ user_id: user.id }, token));
    }
  }, []);

  //fetch rmgt
  const handleRmgt = async () => {
    try {
      const data = await fetchRmgt("mgt", token);
      //////////////console.log(data);
      //////console.log("mgdata", data);
      if (data) {
        dispatch(setRmgt(data));
      }
    } catch (error) {
      console.error(error);
    }
  };
  //onload
  useEffect(() => {
    handleRmgt();
    //////////////console.log('is in ts', rmgt);
  }, []);

  //useSocket for slips

  useEffect(() => {
    //////////////console.log('osdc', bets);
    if (bets && bets.length > 0) {
      ////////////console.log("sent:::", bets);

      if (!socketRef.current.connected) {
        socketRef.current.connect();
      }

      // Create array of objects with gameId and optionId
      const gameOptionIds = bets.map((bet) => ({
        gameId: bet.gameId,
        optionId: bet.optionId,
      }));

      // Emit the new array
      socketRef.current.emit("slipEventIds", gameOptionIds);

      // Listen for 'updatedSlipData' from server
      socketRef.current.on("updatedSlipData", (updatedData) => {
        ////////////console.log("received:::", updatedData);
        // Loop through each updated bet
        updatedData.forEach((updatedBet) => {
          // Dispatch an action to update the odds of the bet in the Redux store
          dispatch(
            updateBetOdds({
              gameId: updatedBet.gameId,
              optionId: updatedBet.optionId,
              odds: updatedBet.odds,
            })
          );
        });
      });

      // Remove the listener when the component unmounts
      return () => {
        socketRef.current.off("updatedSlipData");
      };
    }
  }, [bets, stateSportsEvents, triggerBetslip, dispatch]); // Added 'dispatch' to dependencies

  //30 days aff
  useEffect(() => {
    // Parse the URL to get parameters
    const urlParams = new URLSearchParams(window.location.search);
    const affiliateId = urlParams.get("aff");
    const cashAgentId = urlParams.get("ca");

    // Function to set cookie
    const setCookie = (name, value) => {
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 30); // Set cookie to expire in 30 days
      document.cookie = `${name}=${value}; expires=${expiryDate.toUTCString()}; path=/`;
    };

    // Set cookies if IDs exist
    if (affiliateId) setCookie("affiliateId", affiliateId);
    if (cashAgentId) setCookie("cashAgentId", cashAgentId);
  }, []);

  function DelayedRender({ children, delay = 5000 }) {
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setIsReady(true);
      }, delay);

      return () => clearTimeout(timer);
    }, [delay]);

    if (!isReady) {
      return <Loader />;
    }

    return children;
  }

  //////////testtttttttttttttttttttttttt
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === "http://localhost:3013" && event.data.domainBReady) {
        //finally redirect
        window.location.href = "http://localhost:3013/caff/admin/dashboard";
      }
    };

    window.addEventListener("message", handleMessage);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  //remove remaining betslip if there is still not cleared
  useEffect(() => {
    const betSubmitted = sessionStorage.getItem("betSubmitted");
    if (betSubmitted) {
      sessionStorage.removeItem("betSubmitted");
      dispatch(clearBets());
    }
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/*common routes */}
        <Route
          path="/reset-password"
          element={user ? <Navigate to="/" replace /> : <ResetPassword />}
        />

        <Route path="/deny-access" element={<DenyAccess />} />

        <Route
          path="/"
          element={
            user && user.role === "player" ? (
              <Navigate to="/inplay/?spid=4" replace />
            ) : user && user.role !== "player" ? (
              <Navigate to="/caff/admin/dashboard" replace />
            ) : (
              <HomePage />
            )
          }
        />

        <Route
          path="/partner"
          element={
            user && user.role === "Player" ? (
              <Navigate to="/" replace />
            ) : (
              <PartnerPage />
            )
          }
        />

        <Route path="/view-page/:id" element={<ViewPage />} />

        <Route
          path="/reset-password"
          element={user ? <Navigate to="/" replace /> : <ResetPassword />}
        />

        {/***************************************************
         * ***************** for admins *********************
         * **************************************************/}

        <Route
          path="/partner/register"
          element={user ? <Navigate to="/" replace /> : <PartnerRegister />}
        />

        <Route
          path="/partner/login"
          element={user ? <Navigate to="/" replace /> : <Login />}
        />

        <Route
          path="/caff/admin/dashboard"
          element={
            user && user.role !== "player" ? (
              <AdminDashboard />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/users"
          element={
            user && user.role !== "player" ? (
              <AdminUsers />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/users/:userId"
          element={
            user && user.role !== "player" ? (
              <AdminEditUser />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/events"
          element={
            user && user.role !== "player" ? (
              <AdminEvents />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/exchange-events"
          element={
            user && user.role !== "player" ? (
              <AdminExchangeEvents />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/events/:eventId"
          element={
            user && user.role !== "player" ? (
              <AdminEventDetails />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/exchange-events/:eventId"
          element={
            user && user.role !== "player" ? (
              <AdminExchangeEventDetails />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/tickets"
          element={
            user && user.role !== "player" ? (
              <AdminTickets />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/exchange-tickets"
          element={
            user && user.role !== "player" ? (
              <AdminExchangeTickets />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/casino-slot-games"
          element={
            user && user.role !== "player" ? (
              <AdminCasinoSlot />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/risk-management"
          element={
            user && user.role !== "player" ? (
              <AdminRiskMgt />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/deposits"
          element={
            user && user.role !== "player" ? (
              <AdminDeposits />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/withdrawals"
          element={
            user && user.role !== "player" ? (
              <AdminWithdrawals />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/payment-methods"
          element={
            user && user.role !== "player" ? (
              <AdminPaymentMethods />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/earnings"
          element={
            user && user.role !== "player" ? (
              <AdminEarnings />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/pages"
          element={
            user && user.role === "admin" ? (
              <AdminPages />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/page/edit/:id"
          element={
            user && user.role === "admin" ? (
              <EditPage />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/support/chat"
          element={
            user && user.role !== "player" ? (
              <AdminSupportChat />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/banners-gallery"
          element={
            user && user.role !== "player" ? (
              <AdminBannersGallery />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/settings"
          element={
            user && user.role !== "player" ? (
              <AdminSettings />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route
          path="/caff/admin/email-templates"
          element={
            user && user.role !== "player" ? (
              <EmailTemplates />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route
          path="/caff/admin/user-credit-history/:id"
          element={
            user && user.role !== "player" ? (
              <AdminUserCreditHistory />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route path="/caff/admin/view-score/:id" element={<ViewScore />} />

        <Route
          path="/caff/admin/history"
          element={
            user && user.role !== "player" ? (
              <AdminPartnerHistory />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/payments"
          element={
            user && user.role !== "player" ? (
              <AdminPartnerPayments />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route
          path="/caff/admin/ex/results/:id"
          element={
            user && user.role !== "player" ? (
              <ExResults />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        {/*****************************************************************************
         * *******************for users***************************************************
         * *************************************************************************/}
        <Route
          path="/register"
          element={user ? <Navigate to="/" replace /> : <Register />}
        />

        <Route
          path="/login"
          element={user ? <Navigate to="/" replace /> : <Login />}
        />

        <Route
          path="/top-nav/:navType"
          element={
            user && user.role === "player" ? (
              <TopContentMenu />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/top-nav/:navType/:filterType"
          element={
            user && user.role === "player" ? (
              <TopContentMenu />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />

        <Route
          path="/top-nav-filter/:navType/:filterType"
          element={
            user && user.role === "player" ? (
              <TopContentNavFilterPage />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/presports"
          element={
            user && user.role === "player" ? (
              <PreSportsPage />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/inplay"
          element={
            user && user.role === "player" ? (
              <InplaySportsPage />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/game/:id"
          element={
            user && user.role === "player" ? (
              <FullGamePage />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/exchange"
          element={
            user && user.role === "player" ? (
              <ExchangeSportsPage />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/exchange/:id"
          element={
            user && user.role === "player" ? (
              <ExchangeEventView />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/exchange-mybets/:id"
          element={
            user && user.role === "player" ? (
              <ExchangeMyBets />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/casino/"
          element={
            user && user.role === "player" ? (
              <CasinoPage />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/slot"
          element={
            user && user.role === "player" ? (
              <SlotPage />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/my-bets"
          element={
            user && user.role === "player" ? (
              <MyBet />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/search"
          element={
            user && user.role === "player" ? (
              <SearchPage />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/notifications"
          element={
            user && user.role === "player" ? (
              <DelayedRender delay={10}>
                <NotificationsPage />
              </DelayedRender>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />

        <Route
          path="/messages"
          element={
            user && user.role === "player" ? (
              <MessagesChat />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />

        <Route
          path="/profile"
          element={
            user && user.role === "player" ? (
              <ProfilePage />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />

        <Route
          path="/bank"
          element={
            user && user.role === "player" ? (
              <BankPage />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />

        <Route path="/partner" element={<PartnerPage />} />

        {/* <Route
          path="/view-page/:id"
          element={user ? <ViewPage /> : <Navigate to="/login" replace />}
        /> */}

        {/* <Route path="/my-admin" element={user ? <MyAdmin /> : <Navigate to="/login" replace />} />
            <Route path="/settle-quiz/:id" element={user ? <SettleQuizPage /> : <Navigate to="/login" replace />} /> */}
      </Routes>
    </Suspense>
  );
}

export default App;
